<template>
  <div class="container columns is-vcentered">
    <div class="column is-3">
      <button
        id="submitForm"
        type="submit"
        class="button is-danger is-large is-fullwidth"
        @click.stop.prevent="submit"
      >
        SUBMIT
      </button>
    </div>
    <div id="not-enough-submissions" class="column is-9 is-hidden">
      <div class="notification is-danger">
        You must fill out at least {{ voteRequirement }} {{ pluralName }} to submit this form.
      </div>
    </div>
  </div>
</template>

<script>
import bugsnag from '@/lib/bugsnag';

export default {
  computed: {
    voteRequirement() {
      return this.$store.state.vote_requirement;
    },
    pluralName() {
      if (this.voteRequirement === 1) {
        return 'name';
      }
      return 'names';
    },
  },
  methods: {
    async submit() {
      if (await this.veeValidateCheck() === false) {
        return false;
      }

      if (this.voteCountCheck() === false) {
        return false;
      }

      return this.postForm();
    },
    buildQuery() {
      const sheet = `sheet=${this.$store.state.google_sheet_title || ''}`;
      const secret = `secret=${this.$route.params.secret || ''}`;
      const project_type = `project_type=${this.$store.state.project_type}`;
      const project = `project_id=${this.$store.state.project_id}`;

      return `${sheet}&${secret}&${project}&${project_type}`;
    },
    buildForm() {
      const form = new FormData(document.getElementById('survey'));

      // Overwrites the "names" in each autocomplete field with
      // the record ID we saved when the user entered the nominee
      this.$store.state.nominations.forEach((recordId, category) => {
        form.set(category, recordId);
      });
      return form;
    },
    submissionReport() {
      let submissionData = '';

      // eslint-disable-next-line no-restricted-syntax
      for (const pair of this.buildForm().entries()) {
        submissionData = `${submissionData + pair[0]}: ${pair[1]}, `;
      }
      bugsnag.addMetadata('custom', { submission: submissionData });
    },
    voteCountCheck() {
      if (this.$store.state.vote_count < this.voteRequirement) {
        const notEnough = document.getElementById('not-enough-submissions');
        notEnough.classList.remove('is-hidden');
        setTimeout(() => {
          if (notEnough) {
            notEnough.classList.add('is-hidden');
          }
        }, 5000);
        return false;
      }
      return true;
    },
    async veeValidateCheck() {
      const valid = await this.$parent.validate();
      if (!valid) {
        setTimeout(() => {
          const errors = Object.entries(this.$parent.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error.value.length);
          if (errors.length > 0) {
            this.$parent.refs[errors[0].key].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 100);
        return false;
      }
      return true;
    },
    postForm() {
      // eslint-disable-next-line no-restricted-syntax
      // for (const pair of this.buildForm().entries()) {
      //   console.log(`${pair[0]} ${pair[1]}`);
      // }

      window.fetch(`https://dj5-api.datajoe.com/api/survey?${this.buildQuery()}`, {
        body: this.buildForm(),
        method: 'post',
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }).then((res) => {
        if (!res.ok) {
          this.submissionReport();
          bugsnag.notify(res.statusText);
        }
        this.$router.push('thankyou');
      }).catch((error) => {
        this.submissionReport();
        bugsnag.notify(error);
        this.$router.push('thankyou');
      });
    },
  },
};
</script>
