/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint no-param-reassign: ["error",
  { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

import Vue from 'vue';
import Vuex from 'vuex';
// import state from '../../config/cobiz-bob/state';
import state from './state.json';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...state,
    vote_count: 0,
    nominations: new FormData(),
    project_type: 'form',
  },
  mutations: {
    UPDATE_VOTE_COUNT(state, voteCount) {
      state.vote_count = voteCount;
    },
    CHANGE_EXPIRATION_DATE(state, newDate) {
      state.expiration_date = newDate;
    },
    REMOVE_LOGO(state) {
      state.publication_logo = null;
    },
    CHANGE_LOGO(state, uri) {
      state.publication_logo = uri;
    },
    REMOVE_WELCOME_TEXT(state) {
      state.welcome = null;
    },
    REMOVE_HEADER_TEXT(state) {
      state.survey_title = null;
    },
    REMOVE_SUBHEADER_TEXT(state) {
      state.survey_subtitle = null;
    },
    SET_NOMINATION(state, { name, value }) {
      state.nominations.set(name, value);
    },
  },
  actions: {
    updateVoteCount({ commit }) {
      let voteCount = 0;
      [...document.querySelectorAll('.vote-field')].forEach((input) => {
        if (input.value !== '') {
          voteCount += 1;
        }
      });
      commit('UPDATE_VOTE_COUNT', voteCount);
    },
    changeExpirationDate({ commit }, newDate) {
      commit('CHANGE_EXPIRATION_DATE', newDate);
    },
    changeLogo({ commit }, uri) {
      if (uri === null) {
        commit('REMOVE_LOGO');
      } else {
        commit('CHANGE_LOGO', uri);
      }
    },
    setNomination({ commit }, { name, value }) {
      commit('SET_NOMINATION', { name, value });
    },
  },
  getters: {
    fields: (state) => {
      // state.fields is leftover from when we created field layouts in code
      // The DJX admin interface builds state.layout.
      // At some point we can remove the state.fields if block.
      if (state.fields) {
        const orderedFields = {};
        Object.keys(state.fields).sort().forEach((key) => {
          orderedFields[key] = state.fields[key];
        });
        return orderedFields;
      }
      return state.layout;
    },
    categories: (state) => state.categories
      .filter((header) => Object.keys(header)[0] !== 'ADS')
      .reduce((carry, obj) => {
        /* eslint-disable-next-line */
        carry[Object.keys(obj)[0]] = Object.values(obj)[0];
        return carry;
      }, {}),
  },
});
