import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import ThankYou from './views/ThankYou.vue';
import Stats from './views/Stats.vue';
import store from './store/index';

Vue.use(Router);

const meta = {
  title: `${store.state.survey_title}`,
};

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/thankyou',
      name: 'thankyou',
      component: ThankYou,
      meta,
    },
    {
      path: '/stats',
      name: 'stats',
      component: Stats,
      meta,
    },
    {
      path: '/index.html',
      redirect: { name: 'home' },
    },
    {
      path: '/:secret?',
      name: 'home',
      component: Home,
      meta,
    },
  ],
});
