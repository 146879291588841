import Vue from 'vue';

/**
 * Font Awesome Setup
 * We import just the icons we need so the end bundle size is smaller.
 */
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faUser, faBuilding, faSearch, faEnvelope, faPlus, faClipboard, faGavel, faPhone,
  faStethoscope, faExclamationTriangle, faCheck, faAsterisk, faGlobe, faHome,
  faUsers, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/**
 * Set up the Font Awesome icons we want to use
 */
library.add(
  faUser, faBuilding, faSearch, faEnvelope, faFacebookF, faTwitter,
  faPlus, faLinkedinIn, faClipboard, faGavel, faPhone, faStethoscope,
  faExclamationTriangle, faCheck, faAsterisk, faGlobe, faHome, faUsers,
  faUpload,
);
Vue.component('fa', FontAwesomeIcon);

dom.watch();
