<template>
  <section class="section container columns is-centered">
    <Analytics />
  </section>
</template>

<script>
import Analytics from '@/components/Analytics.vue';

export default {
  components: {
    Analytics,
  },
};
</script>
