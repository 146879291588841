import Vue from 'vue';

/**
 * Initiation library setup
 */
import './init/fontawesome';
import './init/validation';
import './init/analytics';

/**
 * Main application pieces
 */
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/app.scss';

/**
 * Router middleware that handles the changing of the document title
 */
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  return next();
});

/**
 * Instantiate Vue
 */
const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

/**
 * Allow only the testing suite to see the global variable set
 */
if (window.Cypress) window.app = app;
