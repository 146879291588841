<template>
  <section class="welcome-area content" v-if="welcome">
    <div class="container">
      <h2 v-if="welcomeTitle" v-html="welcomeTitle" />
      <div class="columns">
        <div class="column" v-if="welcomeSplash">
          <figure class="image">
            <img :src="welcomeSplash" />
          </figure>
        </div>
        <div class="column">
          <div id="welcomeText" v-html="welcome" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      welcomeTitle: 'welcome_title',
      welcomeSplash: 'welcome_splash',
      welcome: 'welcome',
    }),
  },
};
</script>
