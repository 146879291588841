import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Bugsnag from '@bugsnag/js';
import Vue from 'vue';

Bugsnag.start({
  apiKey: '11f216642ec2529703b62a3c89493f58',
  plugins: [new BugsnagPluginVue()],
});

Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

export default Bugsnag;
