<template>
  <section class="section">
    <h1 class="title is-size-1 has-text-centered" v-if="statsForLevel.length === 0">
      Loading Stats...
    </h1>
    <template v-else>
      <h4 class="title is-4">Statistics</h4>
      <div class="level">
        <div
          class="level-item"
          v-for="stats in statsForLevel"
          :key="stats.year"
        >
          <div>
            <h5 class="title is-5">{{ stats.year }}</h5>
            <p v-for="(stat, header) in statsWithoutYear(stats)" :key="header">
              <span class="has-text-weight-bold">{{ stat }}</span> {{ header }}
            </p>
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6">
          <LineChart
            :chartdata="chartData('Ballots Received')"
            :options="chartOptions('Submissions')"
            :height="null"
          />
        </div>
        <div class="column is-6">
          <LineChart
            :chartdata="chartData('Total Nominations')"
            :options="chartOptions('Nominations')"
            :height="null"
          />
        </div>
        <div class="column is-6">
          <LineChart
            :chartdata="chartData('Unique Nominees')"
            :options="chartOptions('Unique Nominees')"
            :height="null"
          />
        </div>
        <div class="column is-6">
          <LineChart
            :chartdata="chartData('Write-Ins')"
            :options="chartOptions('Write-Ins')"
            :height="null"
          />
        </div>
      </div>
      <div class="columns is-multiline" v-if="googleAnalytics.length > 0">
        <div class="column is-12">
          <h4 class="title is-4">Google Analytics</h4>
        </div>
        <div class="column is-6">
          <LineChart
            :chartdata="gAnalyticsSessionData()"
            :options="chartOptions('Conversion')"
            :height="null"
          />
        </div>
        <div class="column is-6">
          <LineChart
            :chartdata="gAnalyticsCampaignData()"
            :options="chartOptions('Campaigns')"
            :height="null"
          />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import bugsnag from '@/lib/bugsnag';

export default {
  data() {
    return {
      googleAnalytics: [],
      analytics: {},
      statsForLevel: [],
      colors: {
        'This Year': 'hsl(10, 60%, 28%)',
        2019: 'hsl(30, 60%, 28%)',
        2020: 'hsl(70, 60%, 28%)',
        2021: 'hsl(110, 60%, 28%)',
        2022: 'hsl(150, 60%, 28%)',
        2023: 'hsl(190, 60%, 28%)',
        2024: 'hsl(230, 60%, 28%)',
        2025: 'hsl(270, 60%, 28%)',
        Sessions: 'hsl(0, 60%, 28%)',
        Bounces: 'hsl(270, 60%, 28%)',
      },
    };
  },
  computed: {
    ...mapGetters({
      current: 'project/current',
    }),
  },
  methods: {
    chartData(type) {
      const dataPoint = {};
      // We remap the analytics object for this specific type of data only.
      // It also re-keys all the arrays to incrementing integers rather than the weeks

      // We do this because Week 1 of last year's project likely isn't the exact
      // same date as this year's Week 1, but we still want to compare them.

      // We also change this from an object with date => value to just be an
      // array of values that we can throw at the chart to easily show each week.
      Object.keys(this.analytics).forEach((year) => {
        dataPoint[year] = [];
        Object.keys(this.analytics[year]).forEach((key) => {
          dataPoint[year].push(this.analytics[year][key][type]);
        });
      });

      return {
        // Get each week, which at this point is just an integer
        // We add `Week` to it so it's clearer on the labels
        labels: Object.keys(Object.values(dataPoint)[0])
          .map((week) => `Week ${parseInt(week, 10) + 1}`),
        datasets: Object.keys(dataPoint).map((year) => ({
          // Gets the basic set of options (like colors) for this year's chart
          ...this.baseDataSetOptions(year),
          // Gets the data for this year's chart, which is just an array of values
          data: Object.values(dataPoint[year]),
        })),
      };
    },
    chartOptions(title = '') {
      return {
        aspectRatio: 3,
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
        tooltips: {
          intersect: false,
          mode: 'index',
        },
        title: {
          display: true,
          position: 'left',
          text: title,
        },
        scales: {
          yAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
            },
          }],
        },
      };
    },
    gAnalyticsCampaignData() {
      return {
        labels: Array.from(Array(12).keys()).map((index) => `Week ${index + 1}`),
        datasets: [...new Set(this.googleAnalytics.map((item) => item.campaign))]
          .filter((item) => item !== '(not set)')
          .map((dataPoint) => ({
            ...this.baseDataSetOptions(dataPoint),
            data: Object.values(this.googleAnalytics.reduce((carry, week) => {
              if (week.campaign !== dataPoint) {
                return carry;
              }
              carry[week.week] = week.Sessions;
              return carry;
            }, {}))
              .filter((campaign) => campaign > 2),
          })),
      };
    },
    gAnalyticsSessionData() {
      return {
        labels: Array.from(Array(12).keys()).map((index) => `Week ${index + 1}`),
        datasets: ['Sessions', 'Bounces'].map((dataPoint) => ({
          ...this.baseDataSetOptions(dataPoint),
          data: Object.values(this.googleAnalytics.reduce((carry, week) => {
            if (!carry[week.week]) {
              carry[week.week] = 0;
            }
            carry[week.week] += week[dataPoint];
            return carry;
          }, {})),
        })),
      };
    },
    baseDataSetOptions(pointer) {
      return {
        label: pointer,
        borderColor: this.colors[pointer],
        pointBackgroundColor: this.colors[pointer],
        pointBorderColor: this.colors[pointer],
        backgroundColor: 'rgba(0, 0, 0, 0)',
      };
    },
    statsWithoutYear(stats) {
      const { year, ...goodStats } = stats;
      return goodStats;
    },
  },
  mounted() {
    window.fetch(`https://dj5-api.datajoe.com/api/project/${this.$store.state.project_id}/analytics`, {
      method: 'get',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }).then((res) => res.json())
      .then((data) => {
        const analytics = {};
        const statsForLevel = [];

        // Here we have the entire analytics object from the API
        Object.keys(data).forEach((year) => {
          // It's broken up by year, so we start by getting each individual year
          // We set each year on our analytics object
          analytics[year] = {};

          // For each week of data within a year
          Object.keys(data[year]).forEach((week) => {
            // If the week has a dash, it is a date, like "04-30-2020"
            // Otherwise it's a summary, like 'Full Campaign'
            if (week.includes('-')) {
              // If it's a date, we pass that to the analytics object for the charts
              analytics[year][week] = data[year][week];
            } else if (week === 'Google Analytics') {
              this.googleAnalytics = data[year][week];
            } else {
              // If it's a summary, we push it to the level for summarization
              statsForLevel.push({ year, ...data[year][week] });
            }
          });
        });

        this.analytics = analytics;
        this.statsForLevel = statsForLevel;
      })
      .catch((error) => {
        bugsnag.notify(error);
      });
  },
  components: {
    LineChart: () => import('@/components/Charts/LineChart.vue'),
  },
};
</script>
