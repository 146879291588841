/* eslint-disable max-len */
<template>
  <section id="thankyou" class="section has-text-centered">
    <div class="container">
      <h1 class="title is-size-1">
        Thank You for Submitting!
      </h1>
    </div>
    <div class="container section" v-if="socialTitle">
      <h2 class="subtitle is-size-2">
        Share This Form
      </h2>
      <div id="copyable" class="field has-addons">
        <p class="control is-expanded">
          <input id="link" class="input" :value="url">
        </p>
        <p class="control">
          <button id="copyButton" class="button is-dark is-outlined" data-clipboard-target="#link">
            <span class="icon">
              <fa icon="clipboard"></fa>
            </span>
            <span id="copyText">Copy</span>
          </button>
        </p>
      </div>
      <div class="buttons is-centered">
        <a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
          class="button is-large is-dark"
        >
          <fa :icon="['fab', 'facebook-f']" class="fa-fw"></fa>
        </a>
        <a :href="`https://twitter.com/intent/tweet?text=${socialTitle}&url=${url}`"
          class="button is-large is-dark"
        >
          <fa :icon="['fab', 'twitter']" class="fa-fw"></fa>
        </a>
        <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${socialTitle}!&summary=${socialTitle}`"
          class="button is-large is-dark"
        >
          <fa :icon="['fab', 'linkedin-in']" class="fa-fw"></fa>
        </a>
        <a :href="`mailto:?subject=${socialTitle}&body=${socialTitle}%0A${url}`"
          class="button is-large is-dark"
        >
          <fa icon="envelope" class="fa-fw"></fa>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import ClipboardJS from 'clipboard';
import facebookPixel from '@/lib/facebookPixel';
import linkedInInsights from '@/lib/linkedInInsights';

export default {
  computed: {
    socialTitle() {
      return this.$store.state.social_title;
    },
    url() {
      return window.location.host;
    },
  },
  mounted() {
    const clipboard = new ClipboardJS('#copyButton');

    clipboard.on('success', () => {
      document.getElementById('copyText').innerHTML = 'Copied!';
      document.getElementById('copyButton').classList.remove('is-outlined');
    });

    facebookPixel();
    linkedInInsights();
  },
};
</script>
