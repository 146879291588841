<template>
  <section class="content" v-if="hasRules">
    <div class="container">
      <h2>Rules for Submission</h2>
      <ul id="rules">
        <li v-for="rule in rules" :key="rule" v-html="rule" />
      </ul>
      <hr>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      welcome: 'welcome',
      rules: 'rules',
    }),
    hasRules() {
      return this.rules && this.rules.length > 0;
    },
  },
};
</script>
