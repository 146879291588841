<template>
  <section class="content container" v-if="affirmationPoints.length > 0">
    <h2>Confirmation</h2>
    <ul>
      <li v-for="field in affirmationPoints" :key="field" v-html="field" />
    </ul>
    <label class="checkbox has-text-weight-semibold">
        <input type="checkbox" name="affirmation" required="required">
        I Affirm
    </label>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      affirmation: 'affirmation_points',
    }),
    affirmationPoints() {
      return this.affirmation ?? ['This is my one and only submission.'];
    },
  },
};
</script>
